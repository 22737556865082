import TrainTimetable from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-timetable/1.0.0/index.vue'
import consumer_trains_order_getTrainsOrderDetail from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderDetail'
import consumer_trains_product_checkTheRemainingTrainTicketsDetail from "@/lib/data-service/haolv-default/consumer_trains_product_checkTheRemainingTrainTicketsDetail";
import consumer_trains_order_advanceRebooking from "@/lib/data-service/haolv-default/consumer_trains_order_advanceRebooking";
import consumer_trains_order_changeOrderBooking from "@/lib/data-service/haolv-default/consumer_trains_order_changeOrderBooking";
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import {awaitWrap, handlePromise} from '@/page/admin/company-management/staff-management/common/unit'
import moment from "moment";
export default {
    data() {
        return {
            loading: false,
            fromStationCode: '',
            toStationCode: '',
            trainDate: '',
            trainNo: '',
            applyId: '',
            orderNo: '',
            evectionNo: '',
            evectionType: '',
            trainDetail: {},
            seatIndex: 0,
            seatDetail: {},
            userList: '',
            userDetailList: [],
            businessRuleControl: 1,
            form: {
                reason: '出差行程变化',
                otherReason: '',
            },
            columns: ['出差行程变化', '天气因素', '定错车次', '其他'],
            rule: {
                otherReason: [{ required: true, message: '请选择改签原因', trigger: 'blur' }]
            },
            total: 0,
        }
    },
    components: {TrainTimetable},
    created() {
    },
    mounted() {
    },
    activated() {
        this.form = {
            reason: '出差行程变化',
            otherReason: '',
        };
        this.evectionNo = this.$route.query.evectionNo;
        this.evectionType = parseInt(this.$route.query.evectionType);
        this.orderNo = this.$route.query.orderNo;
        this.userList = this.$route.query.userList;
        this.fromStationCode = this.$route.query.fromStationCode;
        this.toStationCode = this.$route.query.toStationCode;
        this.trainDate = this.$route.query.trainDate;
        this.trainNo = this.$route.query.trainNo;
        this.applyId = this.$route.query.applyId;
        this.seatIndex = parseInt(this.$route.query.seatIndex);
        this.refundChangePolicyGet();
        this.getDetail();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterDay(val) {
            if (!val) {
                return ''
            }
            let day = moment(val).format('MM月DD日');
            return day
        },
        filterWeek(val) {
            if (!val) {
                return "";
            }
            let day = moment(val).day();
            if (day === 0) {
                return "周日";
            } else if (day === 1) {
                return "周一";
            } else if (day === 2) {
                return "周二";
            } else if (day === 3) {
                return "周三";
            } else if (day === 4) {
                return "周四";
            } else if (day === 5) {
                return "周五";
            } else if (day === 6) {
                return "周六";
            } else {
                return "";
            }
        },
    },
    methods: {
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get();
            this.businessRuleControl = res.datas.businessRuleControl;
        },
        getDetail() {
            this.loading = true;
            let data = {
                fromStationCode: this.fromStationCode,
                toStationCode: this.toStationCode,
                trainDate: this.trainDate,
                trainNo: this.trainNo,
                applyId: this.applyId,
                evectionNo: this.evectionNo,
            };
            let params = {
                orderNo: this.orderNo
            }
            let promiseList = [consumer_trains_product_checkTheRemainingTrainTicketsDetail(data), consumer_trains_order_getTrainsOrderDetail(params)]
            Promise.all(handlePromise(promiseList)).then(res => {
                this.loading = false;
                if (res[0]) {
                    if (res[0].resultList.length === 0) {
                        return
                    }
                    this.trainDetail = res[0].resultList[0];
                    this.seatDetail = this.trainDetail.trainSeatVoList[this.seatIndex];

                    const userList = this.userList.split(',');
                    this.total = this.seatDetail.seatPrice * userList.length;

                    this.$nextTick(() => {
                        const timeParams = {
                            fromStationCode: this.trainDetail.fromStationCode,
                            toStationCode: this.trainDetail.toStationCode,
                            trainCode: this.trainDetail.trainCode,
                            trainDate: moment(this.trainDetail.trainStartDate).format('YYYY-MM-DD'),
                        };
                        this.$refs.aTrainTimetable.init(timeParams)
                    })
                } else {
                    this.trainDetail = {};
                    this.seatDetail = {};
                }
                if (res[1]) {
                    let passengerList = [];
                    const userList = this.userList.split(',');
                    res[1].datas.passengers.forEach((value, index) => {
                        if (userList.indexOf(value.userId.toString()) >= 0) {
                            passengerList.push(value)
                        }
                    });
                    this.userDetailList = passengerList;
                } else {
                    this.userDetailList = []
                }
            }).catch(() => {
                this.loading = false;
            });
        },
        checkForm() {
            return new Promise(resolve => {
                this.$refs.form.validate((valid) => {
                    resolve(valid)
                })
            })
        },
        async toSubmit() {
            // 校验
            let checkResult = await this.checkForm();
            if (!checkResult) {
                return
            }
            let params = {
                changeArriveDate: this.$moment(this.seatDetail.arriveDate).format('YYYY-MM-DD'),
                changeArriveTime: this.trainDetail.toTime,
                changeFromStationCode: this.trainDetail.fromStationCode,
                changeFromStationName: this.trainDetail.fromStationName,
                changeSeatCode: this.seatDetail.seatCode,
                changeSeatName: this.seatDetail.seatName,
                changeSeatPrice: this.seatDetail.seatPrice,
                changeStartDate: this.$moment(this.trainDetail.trainStartDate).format('YYYY-MM-DD'),
                changeStartTime: this.trainDetail.fromTime,
                changeToStationCode: this.trainDetail.toStationCode,
                changeToStationName: this.trainDetail.toStationName,
                changeTrainCode: this.trainDetail.trainCode,
                gmtChangeArrive: this.$moment(this.trainDetail.arriveDate).format('YYYY-MM-DD') + ' ' + this.trainDetail.toTime + ':00',
                gmtChangeStart: this.$moment(this.trainDetail.trainStartDate).format('YYYY-MM-DD') + ' ' + this.trainDetail.fromTime + ':00',
                orderNo: this.orderNo,
                changeNo: ''
            };

            let userIds = [];
            let passengerSns = [];
            let changeAdultCounts = 0; // 改签成人人数
            let changeChildrenCounts = 0; // 改签儿童人数
            this.userDetailList.forEach(value => {
                passengerSns.push(value.sn);
                userIds.push(value.userId);
                if (value.passengerType === 2) {
                    changeChildrenCounts++;
                } else {
                    changeAdultCounts++;
                }
            });
            let params2 = {
                changeAdultCounts: changeAdultCounts,
                changeChildrenCounts: changeChildrenCounts,
                changeHeadCounts: this.userDetailList.length,
                orderNo: this.orderNo,
                passengerSns: passengerSns,
                changeNo: '',
                overproofApplyList: [],
                reason: this.form.reason === '其他' ? this.form.otherReason : this.form.reason,
                otherReason: this.form.otherReason,
                scheduledWay: this.evectionType,
            };
            let [err, res] = await awaitWrap(consumer_trains_order_advanceRebooking(params))
            if (err) {
                return
            }
            const changeNo = res.datas.changeNo;
            params2.changeNo = res.datas.changeNo;
            let [error, result] = await awaitWrap(consumer_trains_order_changeOrderBooking(params2))
            if (error) {
                return
            }
            // 跳转占座
            this.$router.push({
                name: 'admin-train-change-submit-seat',
                query: {
                    orderNo: this.orderNo,
                    changeNo
                }
            })
        }
    }
}
