// consumer_trains_product_checkTheRemainingTrainTicketsDetail 车次余票详情
const __request = require(`./__request/__request_contentType_json`);

const consumer_trains_product_checkTheRemainingTrainTicketsDetail = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/product/checkTheRemainingTrainTicketsDetail',
        data: pParameter
    };
    return new Promise(function (resolve, reject) {
        resolve(__request(p));
    })
};
module.exports = consumer_trains_product_checkTheRemainingTrainTicketsDetail;